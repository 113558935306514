import hero from "./../assets/img/front-inside.jpeg";
import bilde from "./../assets/img/bred2.1.1.jpg";
import HideAppBar from "../components/HideAppBar";
import classes from "*.module.css";
import { createStyles, Fab, Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import ScrollTop from "../components/ScrollTop";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Checkout from "../components/Checkout";
//@ts-ignore
import Calendar from "@ericz1803/react-google-calendar";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hero: {
      width: "100%",
      height: "80%",
      alignSelf: "right",
    },
  })
);

export default function Home(props: any) {
  const classes = useStyles();

  const API_KEY = "AIzaSyCD66rCU-FKaczhoky3HTfwcmfNK7Pmka0";
  let calendars = [
    {
      calendarId: "a6od0gtksp9qveiut0hjqv44i0@group.calendar.google.com",
      color: "red",
    },
  ];

  return (
    <Grid
      style={{ backgroundColor: "#f5f5f5" }}
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={10}
    >
      <img src={bilde} alt="Logo" className={classes.hero} />

      <Grid
        item
        xs={12}
        md={6}
        style={{
          backgroundColor: "white",
        }}
      >
        <Typography align="center" variant="h1">
          BOBILUTLEIEREN
        </Typography>
        <Typography align="center" variant="h4">
          For øyeblikket har vi èn helintegrert bobil tilgjengelig for utleie –
          Challenger 3008. Inkludert Smart Lounge, fire sitteplasser, fire
          soveplasser (Queensize bed.) Årsmodell 2020. Kan kjøres på førerkort
          klasse B. Overlevering i Halden, eventuelt annet sted i Østfold mot et
          gebyr. Se kalender under for ledige tider (rød strek = opptatt)
        </Typography>
      </Grid>
      <div
        style={{
          backgroundColor: "#f5f5f5",
          padding: "100px",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row-reverse",
          justifyContent: "center",
        }}
      >
        <div style={{ backgroundColor: "white", padding: "55px", flex: "25%" }}>
          <Calendar apiKey={API_KEY} calendars={calendars}></Calendar>
        </div>
        <div style={{ backgroundColor: "white", padding: "10px", flex: "10%" }}>
          <Checkout></Checkout>
        </div>
      </div>

      {/* <div
        style={{
          backgroundColor: "#f5f5f5",
          padding: "10px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      > */}
      <Grid
        item
        xs={12}
        md={6}
        style={{
          backgroundColor: "white",
        }}
      >
        <br></br>
        <Typography align="center" variant="h3">
          KONTAKT OSS
        </Typography>
        <br></br>
        <Typography align="center" variant="body1">
          Ved spørsmål om bobil, priser, tidsrom for leie, brukervennlighet
          eller annet, ring mellom 16.00 og 20.00 på virkedager eller send
          epost.
        </Typography>
        <br></br>
        <Typography align="center" variant="h5">
          Tlf: +47 918 73 260
        </Typography>
        <br></br>
        <Typography align="center" variant="h5">
          Epost: akl@bobilutleieren.no
        </Typography>
        <br></br>
      </Grid>
    </Grid>
  );
}
