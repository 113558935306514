import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    grid:{marginTop:theme.spacing(5)},
    markdown: {
      ...theme.typography.body2,
      padding: theme.spacing(3, 0),
    },
    margin:{
        margin:theme.spacing(3)
    }
  }));
export default function Faq() {
    const classes = useStyles(

    );
    return (
        <Grid container xs={12} md={12} className={classes.grid}  direction="column" alignItems="center">
          
          <Grid item md={6}>
          <Typography  variant="h3" align="center">
          Hva skjer etter forespørsel om bestilling?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          En eventuell bekreftelse på din leieperiode, sammen med leiekontrakt og betalingsinformasjon, vil sendes på opplyste e-mail så snart forespørselen er behandlet. 
          </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
         <Typography  variant="h3" gutterBottom align="center">
            Hvordan og når skjer betalingen?
        </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h5" align="center">
          Du vil motta en faktura sammen med bekreftelsen der det fremgår spesifiserte summer for depositum og leiebeløp. Dette forventes innbetalt innen 7 dager etter tilsendt bekreftelse. Dersom faktura ikke er innbetalt innen 7 dager, vil det forstås som at leieforespørselen er ønsket annullert.          
          </Typography>
          <Divider className={classes.margin}/>
        </Grid>

        <Grid item md={6}>
         <Typography  variant="h3" gutterBottom align="center">
                Kan jeg avbestille?
        </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h5" align="center">
          Avbestilling av leieforholdet er kostnadsfritt inntil 7 dager etter mottatt bekreftelse på leieforholdet. Ved senere avbestilling vil leietaker belastes som følger:
          </Typography>
          <br></br>
          <Typography variant="h5" align="left">
          •	    Inntil 21 dager før start leieperiode: 50 % av leiesummen tilbakeføres leietaker.
          </Typography>
          <br></br>
          <Typography variant="h5" align="left">
          •	    Inntil 14 dager før start leieperiode: 25 % av leiesummen tilbakeføres leietaker.
          </Typography>
          <br></br>
          <Typography variant="h5" align="left">
          • 	Senere enn 14 dager før leieperiode: 0 % av leiesummen tilbakeføres leietaker.
          </Typography>
          <br></br>
          <Typography variant="h5" align="left">
          •	    Depositumet vil alltid tilbakeføres leietaker ved avbestilling.
          </Typography>
          <Divider className={classes.margin}/>
        </Grid>

        <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Hvordan fungerer depositumet?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
              Praksis for depositumet, kroner 10.0000, er at det vil tilbakebetales så snart det er avklart at det ikke foreligger utestående utgifter som følge av leietakers leieforhold (eks. bøter, bompenger, ferger, skader, etc.).
          </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Hvem kan leie og kjøre bobilen?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Leietaker må være fylt 23 år og inneha gyldig førerkort klasse B. Brudd på dette vil kunne innebære at bilen ikke utleveres og at leien må betales. Hver ekstra sjåfør er gratis så lenge nevnte kriterier er oppfylt. Antall sjåfører opplyses til Bobilutleieren 
          </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
         <Typography  variant="h3" gutterBottom align="center">
         Hvordan fungerer inn- og utlevering?
        </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h5" align="left">
          • I høysesongen er det innlevering av bilen senest kl. 12.00 – med mindre annet er avtalt. 
          </Typography>
          <Typography variant="h5" align="left">
          • I høysesongen er det utlevering av bilen kl. 16.00 – med mindre annet er avtalt.  
          </Typography>
          <Typography variant="h5" align="left">
          • I lavsesong avtales klokkeslett.
          </Typography>
          <br></br>
          <Typography variant="h4" align="center">
          Ved innlevering:
          </Typography>
          <br></br>
          <Typography variant="h5" align="left">
          Bilen skal være ryddet for søppel.
          </Typography>
          <br></br>
          <Typography variant="h5" align="left">
          Bilen skal leveres tilbake med full dieseltank – kvittering fra siste fylling fremvises ved innlevering. Ønskes det at Bobilutleieren skal fylle tanken, tilkommer et gebyr på 200 kroner, samt den faktiske kostnaden for drivstoffet. Dette trekkes fra depositumet.          </Typography>
          <br></br>
          <Typography variant="h4" align="center">
          Ved utlevering:
          </Typography>
          <br></br>
          <Typography variant="h5" align="left">
          Når du skal hente bilen, må du regne med at formaliteter og anvisninger tar ca. en time. Sett derfor av god tid når du skal hente bilen. Sammen med vår medarbeider bør du påse at bilen er fullt utstyrt og at eksisterende slitasjetegn blir bemerket i overtakelsesprotokollen.
          </Typography>
          <br></br>
          <Divider className={classes.margin}/>
        </Grid>

        <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Hva om jeg får bøter?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Leietaker er ansvarlig for alle bøter på bilen under leieforholdet og det forventes opplyst om tilkomne bøter til Bobilutleieren. Parkeringsbøter o.l. eller ulovlig bruk av avgiftsfri diesel, som leietaker ikke har oppgjort, belastes leietaker i ettertid. I tillegg kommer et administrasjonsgebyr på kr 500,-.
          </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Hvordan fungerer det med bompenger/fergekostnader, etc?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Leietaker betaler alle bomavgifter, fergekostnader, etc. Dette trekkes eventuelt fra depositumet. Det vil gå raskere med tilbakebetaling av depositum dersom leietaker opplyser om alle slike utgifter ved innlevering. 
          </Typography>
          <br></br>
          <Typography variant="h5" align="center">
          Dersom det skulle tilkomme krav om slike kostnader fra leietakers leieperiode i ettertid, vil dette belastes leietaker via ettersendt faktura dersom depositum allerede er tilbakebetalt.
          </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Hva skjer ved skader?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Skader som forårsakes av leietaker under leieforholdet belastes etter kostnad for reparasjon. Bulker og småskader som reduserer bilens verdi belastes etter takst. Alternativt, dersom forsikringen må benyttes for å dekke skaden, belastes egenandelen på forsikringen – her: kr 12.000,- per skadesak. Depositumet kan brukes til delbetaling av egenandelen.
          </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Hvordan er forsikringen?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Leieprisen inkluderer kasko og ansvarsforsikring. Ved skade som utløser bruk av forsikringen, må leietaker betale egenandel på inntil kr. 12.000.- pr. skade. Ved glasskade er egenandelen kr 2.000,-. 
           </Typography>
           <br></br>
           <Typography variant="h5" align="center">
           Ved tyveri eller ran dekker bobilens forsikring kun utstyr som tilhører bobilen.
          </Typography>
          <Typography variant="h5" align="center">
          Vi anbefaler at leietaker har egen reiseforsikring.
          </Typography>
          <Typography variant="h5" align="center">
          Ved feil påfylling av drivstoff, bruk av rød diesel eller annen skade som leietaker er ansvarlig for og som ikke dekkes av kaskoforsikringen, må alle utgifter dekkes av leietaker.
          </Typography>
          <br></br>
          <Typography variant="h5" align="center">
          NB! Ved skade må det fylles ut skademelding og at Bobilutleieren kontaktes snarest mulig
          </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Kan man røyke i bobilen?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Det er forbudt å røyke i Bobilen. Ved brudd på forbudet tilkommer avgift på kr 10.000,- for total rens og ekstra rengjøring.
           </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Kan vi ha med dyr i bobilen? 
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Det er kun tillatt med dyr i henhold til avtale. Ekstrakostnad 500 kroner pr uke.
          </Typography>
          <br></br>
          <Typography variant="h5" align="center">
          Spørsmål om å ha med dyr gjøres ved forespørsel om bestilling. Ved brudd på manglende tillatelse, tilkommer avgift på kr 10.000,- for total rens og ekstra rengjøring.
          </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Hva er ditt ansvar? 
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          I leieperioden har leietaker ansvar for bobilen ved bl.a. å etterfylle spylervæske, kontrollere og etterfylle nødvendig olje, vann på radiator etc. Vi forutsetter en forsiktig behandling av bobilen slik at turen blir vellykket.
          </Typography>
          <br></br>
          <Typography variant="h5" align="center">
          Dersom uforutsette problemer oppstår, er leietaker pliktig å ta kontakt med Bobilutleieren for å prøve å løse problemet. 
          </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Hva skjer hvis bilen skulle havarere?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Dersom bilen skulle havarere under leieperioden og dette ikke skyldes leietaker, vil leiesum tilbakebetales forholdsmessig. Depositum tilbakebetales i hht praksis for depositumet. 
          </Typography>
          <br></br>
          <Typography variant="h5" align="center">
          Dersom bilen grunnet feil eller skader ikke kan utleveres som avtalt, får leietaker refundert leie for det antall dager som bobilen er til reparasjon. Dersom bilen blir mer enn 24 timer forsinket, vil leietaker kunne annullere leieavtalen kostnadsfritt og få tilbake full leiesum og depositum. 
          </Typography>
          <br></br>
          <Typography variant="h5" align="center">
          Bobilutleieren gir ikke erstatning for tapt ferie/arbeidsfortjeneste dersom bilen skulle havarere eller ikke kan utleveres som følge av havari/skader/feil. 
          </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Kan jeg bruke bobil på festival og sportsarrangement?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Bruk av bobilen på festival eller sportsarrangement er kun tillatt etter avtale. Brudd på dette vil kunne innebære avgift opp til tilsvarende depositumet.
           </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Hvorfor er det obligatorisk å kjøpe vasking?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Når du skal på tur og ser frem til en god ferieopplevelse, er det viktig at bilen er ren og delikat. For å sikre at alle får en slik god ferieopplevelse, og for å unngå eventuelle forsinkelser ved utlevering til neste leietaker grunnet manglende kvalitet på vasking, vil bobiluteleieren sørge for en kvalitetssikret rengjøring. 
          </Typography>
          <br></br>
          <Typography variant="h5" align="center">
          Det forutsettes at bilen er normalt brukt og ryddet for søppel. Ved unormalt møkkete og skitten bil vil det kunne tilkomme et gebyr for å dekke ekstrakostnader tilknyttet rengjøring. Dette vil bli trukket fra depositumet.
          </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Er det vektbegrensninger på hvor mye bagasje jeg kan ha med?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Det er leietakers ansvar å ikke kjøre med for høy totalvekt. Normal mengde bagasje vil ikke utgjøre et problem.
           </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Hva hvis det ikke er mer gass igjen?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Leiekostnadene dekker inntil en flaske gass. Dersom gassflasken skulle gå tom under din leieperiode, vil bobilutleieren refundere en stk. påfylling av gass mot fremvisning av kvittering.
           </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Hvordan fungerer de ulike systemene i bobilen?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Det ligger instrukser i bilen som hjelper deg når du er på tur. Det er også laget instruksjonsvideoer for ulike funksjoner som ligger på vår hjemmeside.
           </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Kan jeg reise utenfor Norge?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Dette må avklares med Bobilutleieren ved forespørsel om bestilling. Enkelte land har innreiserestriksjoner. 
           </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Fungerer bilen til Free-camping?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Ja. Bilen er utstyrt med solcellepanel og Lithium bodelsbatteri. Dette gjør bilen godt egnet til free-camping. Ved free-camping vil alt av styrelys og lys belastes bodelsbatteriet. Varmekilde fra Webasto forutsetter minimum 25 % full dieseltank. Kjøleskapet benytter gass.
           </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Hva er inkludert av kilometer i leieprisen?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Inkludert i leieprisen er det 300 km pr dag. 
           </Typography>  
          <Typography variant="h5" align="center">
          Kjøring utover dette belastes med 4,50 kroner pr. kjørte km.
           </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Kan jeg levere eller hente bilen andre steder enn i Halden?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Ja. Mot et gebyr på kroner 500 vil vi kunne levere og motta bilen et annet sted i Østfold.
           </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Kan jeg låne bort bilen til andre i min leieperiode?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Det er ikke tillatt at andre enn de som er opplyst skal være med leietaker i leieperioden å kjøre/låne bilen. Brudd på dette vil kunne innebære avgift tilsvarende depositumet.
           </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Hvordan og hvor kan vi fylle på friskvann?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Du kan fylle på friskvann på mange bensinstasjoner, campingplasser og på tusenvis av forsynings- og avfallsstasjoner. Det anbefales at drikkevann medbringes eksplisitt.
           </Typography>
           <br></br>  
          <Typography variant="h5" align="center">
          Det er vedlagt egen vannslange som kun benyttes til påfylling av vann. Det anbefales at man ikke kjører med for mye friskvann og at man heller fyller på vann nærmere destinasjonen.
           </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Når og hvor kan jeg tømme toalett-tanken?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Det lyser et rødt lys ved spylingsknappen når toalettet er fullt. Toalettet må da tømmes før det benyttes på nytt (for å unngå at toalettet flyter over og kloakk renner ned i bilens hulrom).
           </Typography>
           <br></br>  
          <Typography variant="h5" align="center">
          Tømming kan gjøres på Campingplasser, egne tømmeplasser eller i et ordinært toalett. Vær obs på å ikke legge skrulokket slik at det faller ned i avløpet. Husk etter tømming å legge inn en porsjonspose med sanitærstoff.
           </Typography>
           <br></br>  
          <Typography variant="h5" align="center">
          NB! Vær særlig observant på at toalett-tanken dyttes helt inn og «låses». Dersom dette ikke sikres, vil tanken kunne flytte på seg under kjøring og ved benyttelse av toalett vil kloakk kunne ledes til uønskede steder. Dersom slik «skade» oppstår, vil leietaker kreves for kostnader knyttet til rengjøring/reparasjon.
           </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Hvor kan jeg tømme gråvannet?
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Dette kan tømmes over ethvert sted som tar i mot overskuddsvann, f.eks mottak av overskuddsvann på veien, kumlokk. 
           </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Kan jeg parkere min bil gratis hos Bobilutleieren? 
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Dette kan gjøres ved avtale.
           </Typography>
         <Divider className={classes.margin}/>
         </Grid>

         <Grid item md={6}>
          <Typography  variant="h3" gutterBottom align="center">
          Hvorfor er det ikke tillatt å brenne stearinlys inne i bobilen? 
        </Typography>
        </Grid>
        <Grid item md={6}>  
          <Typography variant="h5" align="center">
          Det er brannfarlig.
           </Typography>
         <Divider className={classes.margin}/>
         </Grid>

        </Grid>

        
      );
}