import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useStoreState, useStoreActions } from "../hooks/storeHooks";

export default function AddressForm() {
  const order = useStoreState((state) => state.order);
  const setOrder = useStoreActions((action) => action.setOrder);
  const [firstName, setFirstName] = React.useState(order.firstName);
  const [lastName, setLastName] = React.useState(order.lastName);
  const [email, setEmail] = React.useState(order.email);
  const [addresse, setAddresse] = React.useState(order.address);
  const [city, setCity] = React.useState(order.city);
  const [postNumber, setPostNumber] = React.useState(order.postNumber);
  const [phoneNumber, setPhoneNumber] = React.useState(order.phoneNumber);
  const [overAge, setOverAge] = React.useState(order.overAge);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Personinformasjon
      </Typography>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              value={firstName}
              onChange={(
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                setFirstName(event.target.value);
                order.firstName = event.target.value;
                setOrder(order);
              }}
              id="firstName"
              name="firstName"
              label="Fornavn"
              fullWidth
              autoComplete="given-name"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              value={lastName}
              onChange={(
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                setLastName(event.target.value);
                order.lastName = event.target.value;
                setOrder(order);
              }}
              id="lastName"
              name="lastName"
              label="Etternavn"
              fullWidth
              autoComplete="family-name"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              value={email}
              onChange={(
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                setEmail(event.target.value);
                order.email = event.target.value;
                setOrder(order);
              }}
              id="epsot"
              name="epost"
              label="Epost"
              fullWidth
              autoComplete="email"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              value={phoneNumber}
              onChange={(
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                setPhoneNumber(event.target.value);
                order.phoneNumber = event.target.value;
                setOrder(order);
              }}
              id="phoneNumber"
              name="phoneNumber"
              label="Tlf.nr."
              fullWidth
              autoComplete="tel"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              value={addresse}
              onChange={(
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                setAddresse(event.target.value);
                order.address = event.target.value;
                setOrder(order);
              }}
              id="addresse"
              name="addresse"
              label="Adresse"
              fullWidth
              autoComplete="shipping address-line1"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              value={city}
              onChange={(
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                setCity(event.target.value);
                order.city = event.target.value;
                setOrder(order);
              }}
              id="city"
              name="city"
              label="By"
              fullWidth
              autoComplete="shipping address-level2"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              value={postNumber}
              onChange={(
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                setPostNumber(event.target.value);
                order.postNumber = event.target.value;
                setOrder(order);
              }}
              id="zip"
              name="zip"
              label="Postnummer"
              fullWidth
              autoComplete="shipping postal-code"
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  required
                  checked={overAge}
                  onChange={(e) => {
                    setOverAge(e.target.checked);
                    order.overAge = e.target.checked;
                    setOrder(order);
                  }}
                  color="secondary"
                  name="confirmAgeOver23"
                  value="yes"
                />
              }
              label="Jeg er 23 år eller eldre."
            />
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}
