import firebase from "firebase";
import "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY as string ,
  authDomain: process.env.REACT_APP_AUTHDOMAIN as string ,
  databaseURL: process.env.REACT_APP_DB as string,
  projectId: process.env.REACT_APP_PID as string,
  storageBucket: process.env.REACT_APP_SB as string,
  messagingSenderId: process.env.REACT_APP_SID as string,
  appId: process.env.REACT_APP_APPID as string,
  measurementId: process.env.REACT_APP_MID as string,
};
  
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
export const db = firebase.firestore();
// export const auth = firebase.auth();
// export const storage = firebase.storage();
export const analytics = firebase.analytics();

export default firebase;
