import logo from "./../assets/img/bobillogo4.svg";
import {
  AppBar,
  Box,
  Button,
  Container,
  createStyles,
  CssBaseline,
  Fab,
  makeStyles,
  Slide,
  Theme,
  Toolbar,
  Typography,
  useScrollTrigger,
  Zoom,
  Link,
} from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      flexGrow: 1,
    },
  })
);

function HideOnScroll(props: any) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function HideAppBar(props: any) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar>
          <Toolbar>
            <NavLink
              to="/"
              className={classes.title}
              style={{ padding: "5px" }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{ height: "auto", maxWidth: "11%" }}
              />
            </NavLink>
            <NavLink
              to="/faq"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <Button color="inherit" size="large">
                FAQ
              </Button>
            </NavLink>
            <NavLink
              to="/priser"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <Button color="inherit" size="large">
                Priser
              </Button>
            </NavLink>
            {/* <NavLink to="/contract" style={{color: "inherit", textDecoration: "none"}} >
              <Button color="inherit" size="large">Kontrakt</Button>
            </NavLink>
            <NavLink to="/contract" style={{color: "inherit", textDecoration: "none"}} >
              <Button color="inherit" size="large">Bildegalleri</Button>
            </NavLink> */}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </React.Fragment>
  );
}
