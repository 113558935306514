import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  grid: { marginTop: theme.spacing(5) },
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  margin: {
    margin: theme.spacing(3),
  },
}));
export default function Faq() {
  const classes = useStyles();
  return (
    <Grid
      container
      xs={12}
      md={12}
      className={classes.grid}
      direction="column"
      alignItems="center"
    >
      <Grid item md={6}>
        <Typography variant="h4" align="center">
          Priser i høysesong - skolens ferier:
        </Typography>
        <br></br>
      </Grid>
      <Grid item md={6}>
        <Typography variant="h6" align="left">
          Uke - 15 000 kroner
          <br></br>
          Dag – 2 500 (minimum 3 dager)
        </Typography>
        <Divider className={classes.margin} />
      </Grid>

      <Grid item md={6}>
        <Typography variant="h4" align="center">
          Priser lavsesong:
        </Typography>
        <br></br>
      </Grid>
      <Grid item md={6}>
        <Typography variant="h6" align="left">
          Uke - 10 000 kroner
          <br></br>
          Dag – 2 000 (minimum 3 dager)
        </Typography>
        <Divider className={classes.margin} />
      </Grid>

      <Grid item md={6}>
        <Typography variant="h4" align="center">
          Faste obligatoriske kostnader:
        </Typography>
        <br></br>
      </Grid>
      <Grid item md={6}>
        <Typography variant="h6" align="left">
          Tømming av spillvann og toalett, innvendig og utvendig vask: 1 990
          kroner
        </Typography>
        <Divider className={classes.margin} />
      </Grid>

      <Grid item md={6}>
        <Typography variant="h4" align="center">
          Ekstra tilvalg:
        </Typography>
        <br></br>
      </Grid>
      <Grid item md={6}>
        <Typography variant="h6" align="left">
          Kjøre i utlandet: må avtales
          <br></br>
          Ha med kjæledyr: 500 (Krever godkjennelse)
          <br></br>
          Håndklesett (4 stk): 500 (Kjekt å ha – mulighet til å velge antall og
          kostnad 125 pr sett)
          <br></br>
          Sengesett (dyne, pute, laken, dyne-/putetrekk 4 stk): 800 (Kjekt å ha
          – mulighet til å velge antall og kostnad 200 pr sett)
        </Typography>
        <Divider className={classes.margin} />
      </Grid>

      <Grid item md={6}>
        <Typography variant="h4" align="center">
          Depositum 10 000 kroner.
        </Typography>
        <br></br>
        <Divider className={classes.margin} />
      </Grid>

      <Grid item md={6}>
        <Typography variant="h4" align="center">
          Andre utgifter
        </Typography>
        <br></br>
      </Grid>
      <Grid item md={6}>
        <Typography variant="h6" align="left">
          Eventuelt gebyr for levering av bilen annet sted.
          <br></br>
          4,50 kroner pr. kjørte km over 300 km pr dag
          <br></br>
          Bompenger/fergekostander, etc trekkes fra depositum.
        </Typography>
        <Divider className={classes.margin} />
      </Grid>
    </Grid>
  );
}
