import * as React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ExtraOrder } from "../constants/types";
import { useStoreActions, useStoreState } from "../hooks/storeHooks";
import eo from "date-fns/esm/locale/eo/index.js";

export default function DetailesForm() {
  const mandatory = [
    { name: "Innvendig vask ved retur", included: true, price: 1999 },
    { name: "Ytre vask ved retur", included: true, price: 2000 },
    {
      name: "Tømming av gråvann og toalett ved retur",
      included: true,
      price: 1500,
    },
  ];

  const included = [
    {
      name: "Kjøkkenutstyr",
      included: true,
      price: 0,
    },
    {
      name: "Forsikring: Kasko",
      price: 0,
    },
    {
      name: "Kjøring: 350km per døgn",
      price: 0,
    },
    {
      name: "Utemøbler",
      included: true,
      price: 0,
    },
    {
      name: "Kjemikalier til toalett",
      included: true,
      price: 0,
    },
    {
      key: "gassflask",
      name: "Gassflaske (5kg)",
      included: false,
      price: 0,
      checked: false,
    },
  ];
  const EXTRAORDERS: ExtraOrder[] = [
    {
      key: "abroad",
      name: "Skal kjøre i utlandet, må avtales",
      included: false,
      price: 0,
      checked: false,
    },

    {
      key: "animal",
      name: "Ha med kjæledyr, krever godkjennelse",
      included: false,
      price: 500,
      checked: false,
    },
    {
      key: "towl",
      name: "Håndklesett (4 stk)",
      included: false,
      price: 500,
      checked: false,
    },
    {
      key: "bedStuff",
      name: "Sengesett (dyne, pute, laken, dyne-/putetrekk 4 stk)",
      included: false,
      price: 800,
      checked: false,
    },
  ];

  const order = useStoreState((state) => state.order);
  const checkedOrders = EXTRAORDERS.map<ExtraOrder>((eo) => {
    if (order.extraorders.filter((e) => eo.key === e.key).length) {
      eo.checked = true;
    } else {
      eo.checked = false;
    }
    return eo;
  });

  const setOrder = useStoreActions((action) => action.setOrder);
  const [extraOrders, setExtraOrders] = React.useState<ExtraOrder[]>(
    checkedOrders
  );

  React.useEffect(() => {
    console.log(order);
  }, [order]);

  const handleClick = (e: any) => {
    const extraOrder = extraOrders.find((eo) => eo.key == e.target.name);
    if (extraOrder === undefined) {
      return;
    }
    extraOrder.checked = e.target.checked;
    const checkedOrders = extraOrders.map((eo) => {
      if (eo.key === extraOrder.key) {
        return extraOrder;
      } else {
        return eo;
      }
    });

    setExtraOrders(checkedOrders);
    if (!order.extraorders.includes(extraOrder) && e.target.checked) {
      order.extraorders.push(extraOrder);
    }

    if (
      order.extraorders.filter((exo) => extraOrder.key === exo.key).length &&
      !e.target.checked
    ) {
      order.extraorders = order.extraorders.filter(
        (eo) => eo.key != extraOrder.key
      );
    }
    console.log(order);

    setOrder(order);
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Inkludert
      </Typography>
      <Grid container alignItems="stretch" spacing={0} direction="column">
        {included.map((eo: any) => {
          return (
            <FormControlLabel
              key={eo.name}
              control={
                <Checkbox
                  key={eo.name + "check"}
                  disabled
                  checked={true}
                  color="secondary"
                  value="yes"
                />
              }
              label={
                eo.price !== 0 ? eo.name + " " + eo.price + " kr" : eo.name
              }
            />
          );
        })}
      </Grid>
      <br />
      <Typography variant="h6" gutterBottom>
        Ekstra tilvalg
      </Typography>
      <Grid container alignItems="stretch" spacing={0} direction="column">
        {extraOrders.map((eo) => {
          if (!eo.included) {
            return (
              <FormControlLabel
                key={eo.key}
                control={
                  <Checkbox
                    key={eo.key + "check"}
                    checked={eo.checked}
                    color="secondary"
                    name={eo.key}
                    value="yes"
                    onChange={handleClick}
                  />
                }
                label={
                  eo.price !== 0 ? eo.name + " " + eo.price + " kr" : eo.name
                }
              />
            );
          }
        })}
      </Grid>
      <br />
      <Typography variant="h6" gutterBottom>
        Obligatorisk
      </Typography>
      <Grid container alignItems="stretch" spacing={0} direction="column">
        {mandatory.map((eo: any) => {
          return (
            <FormControlLabel
              key={eo.name}
              control={
                <Checkbox
                  key={eo.name + "check"}
                  disabled
                  checked={true}
                  color="secondary"
                  name="saveCard"
                  value="yes"
                />
              }
              label={
                eo.price !== 0 ? eo.name + " " + eo.price + " kr" : eo.name
              }
            />
          );
        })}
      </Grid>
    </React.Fragment>
  );
}
