import { action, createStore } from "easy-peasy";
import { Order } from "../constants/types";
import { StoreModel } from "./storeModel";

const storeModel: StoreModel = {

  // do thios work?
  order: {endTime: new Date(), extraorders:[], firstName: "", lastName: "", startTime: new Date(), address: "", city: "", email: "", overAge: false, phoneNumber: "", postNumber:""},
  setOrder: action((state, payload) => {
      state.order= payload;
    }),
};

const store = createStore(storeModel);
export default store;