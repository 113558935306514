import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import { db } from "./FirebaseContext";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { grey, blue } from "@material-ui/core/colors";
import HideAppBar from "./components/HideAppBar";
import Contract from "./pages/Contract";
import store from "./store/store";
import { StoreProvider } from "easy-peasy";
import Home from "./pages/Home";
import Faq from "./pages/Faq";
import Priser from "./pages/Priser";
const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      light: blue[200],
      main: grey[100],
      dark: blue[900],
      contrastText: grey[900],
    },
    secondary: {
      light: blue[100],
      main: blue[300],
      dark: blue[900],
      contrastText: grey[900],
    },
    // type: "light",
  },
});
const {
  breakpoints,
  typography: { pxToRem },
} = defaultTheme;
function App() {
  const theme = {
    ...defaultTheme,
    overrides: {
      MuiTypography: {
        h1: {
          fontSize: "5rem",
          [breakpoints.down("xs")]: {
            fontSize: "3rem",
          },
        },
      },
    },
  };
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <HideAppBar></HideAppBar>
          <div>
            <Switch>
              <Route path="/contract">
                <Contract />
              </Route>
              <Route path="/faq">
                <Faq />
              </Route>
              <Route path="/priser">
                <Priser />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </Router>
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
