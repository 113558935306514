import * as React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useStoreActions, useStoreState } from "../hooks/storeHooks";
import { useState, useEffect } from "react";

export default function DateForm() {
  const order = useStoreState((state) => state.order);
  const setOrder = useStoreActions((action) => action.setOrder);
  const [startTime, setStartTime] = useState<Date>(order.startTime);
  const [endTime, setEndTime] = useState<Date>(order.endTime);

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setStartTime(date);
      order.startTime = date;
      setOrder(order);
    }
  };
  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      setEndTime(date);
      order.endTime = date;
      setOrder(order);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Tidspunkt
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <KeyboardDatePicker
              required
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="start-date"
              label="Startdato"
              value={startTime}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <KeyboardDatePicker
              required
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="end-date"
              label="Sluttdato"
              value={endTime}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          
        </Grid>
      </React.Fragment>
    </MuiPickersUtilsProvider>
  );
}
