import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import { useStoreState } from "../hooks/storeHooks";

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

export default function Review() {
  const classes = useStyles();
  const order = useStoreState((state) => state.order);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Ordre Oversikt
      </Typography>
      <List disablePadding>
        {order.extraorders.map((product) => (
          <ListItem className={classes.listItem} key={product.name}>
            <ListItemText primary={product.name} secondary={product.name} />
            <Typography variant="body2">{product.price}</Typography>
          </ListItem>
        ))}
        <ListItem className={classes.listItem}>
          <ListItemText primary="Total ekstra tilvalg" />
          <Typography variant="subtitle1" className={classes.total}>
            {order.extraorders
              .map((e) => e.price)
              .reduce((eoA, eoB) => eoA + eoB, 0)}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Leieperiode
          </Typography>
          <Typography gutterBottom>
            {"Fra: " + order.startTime.toDateString()}
          </Typography>
          <Typography gutterBottom>
            {"Til: " + order.endTime.toDateString()}
          </Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Bestiller
          </Typography>
          <Grid container>
            <Typography gutterBottom>
              {order.firstName + " " + order.lastName}
            </Typography>
            <Typography gutterBottom>{order.email}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
